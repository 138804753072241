import React, { useState, useEffect } from 'react'
import { supabase } from '../../supabaseClient'
import { TextArea, Button, InlineLoading } from '@carbon/react'
import FormattedOutput from '../../components/FormattedOutput'
import { Link } from 'react-router-dom'

const Diff = require('diff')

const EnglishPage = () => {
    const [inputText, setInputText] = useState('') // is the current user input
    const [requestText, setRequestText] = useState('') // is the actual text that was last sent to the edge function
    const [response, setResponse] = useState({ value: '', loading: false }) // is the response from the edge function

    const [ready, setReady] = useState(false)

    const [session, setSession] = useState(null)

    const [diff, setDiff] = useState([])

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        let diff = Diff.diffWords(requestText.trim(), response.value.trim())
        setDiff(diff)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response.value])

    const invokeFunction = async () => {
        setRequestText(inputText)
        setResponse({ value: '', loading: true })
        const { data, error } = await supabase.functions.invoke("english", {
            body: {
                text: inputText,
            },
        })
        if (error) alert(error)
        setResponse({ value: data.result.choices[0].message.content, loading: false })
    }

    function handleInputChange(e) {
        setInputText(e.target.value)

        if (e.target.value.length >= 10) setReady(true)
        else setReady(false)
    }

    function clearTextFields() {
        setInputText('')
        setRequestText('')
        setResponse({ value: '', loading: false })
    }

    const CreateAccountHint = (props) => {
        if (props.signedIn) {
            return <></>
        }
        return <div className="create-account-hint">
            <p>Um mehr als 100 Zeichen zu verbessern, musst du dich zuerst <Link to="/login">anmelden</Link> oder <Link to="/login">registrieren</Link>.</p>
        </div>
    }

    const handleClick = (index: number) => {
        const contextSize = 20 // number of surrounding elements to include
        const startIndex = Math.max(0, index - contextSize)
        const endIndex = Math.min(diff.length - 1, index + contextSize)
        const context = diff.slice(startIndex, endIndex + 1)
        
        // open the inspektor toggletip using the context
        console.log(context)
    }

    return <div>
        <h1>Englisch-GPT4-Verbesserer</h1>
        <h2>Verbessert alle Arten von Fehlern und hebt diese hervor.</h2>
        <br />

        <CreateAccountHint signedIn={session} />

        <TextArea labelText="Texteingabe" placeholder="Copy your essay here..." enableCounter maxCount={session ? 2048 : 100} onChange={handleInputChange} value={inputText} />
        <Button onClick={invokeFunction} disabled={!ready || response.loading} >Absenden</Button>
        <Button onClick={clearTextFields} kind="secondary" >Zurücksetzen</Button>
        <InlineLoading status={response.loading ? 'active' : 'inactive'} />

        <div className="output">
            <FormattedOutput disabled={response.loading || !response.value} diff={diff} onClick={handleClick} />
        </div>
    </div>
}

export default EnglishPage