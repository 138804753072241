import React from 'react';
import {
    Header,
    HeaderContainer,
    HeaderName,
    HeaderNavigation,
    HeaderMenuButton,
    HeaderMenuItem,
    SkipToContent,
    SideNav,
    SideNavItems,
    HeaderSideNavItems,
} from '@carbon/react';

import { Link } from 'react-router-dom';


const MainHeader = () => (
    <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
            <Header aria-label="Verbesserer">
                <SkipToContent />
                <HeaderMenuButton
                    aria-label="Menü öffnen"
                    onClick={onClickSideNavExpand}
                    isActive={isSideNavExpanded}
                />
                <HeaderName element={Link} to="/" prefix="Motz'">
                    Verbesserer
                </HeaderName>

                <HeaderNavigation aria-label="Verbesserer">
                    <HeaderMenuItem element={Link} to="/english">English</HeaderMenuItem>
                    <HeaderMenuItem element={Link} to="/login">Account</HeaderMenuItem>
                </HeaderNavigation>
                <SideNav
                    aria-label="Side navigation"
                    expanded={isSideNavExpanded}
                    isPersistent={false}
                >
                    <SideNavItems>
                        <HeaderSideNavItems>
                            <HeaderMenuItem element={Link} to="/login">Account</HeaderMenuItem>
                        </HeaderSideNavItems>
                    </SideNavItems>
                </SideNav>

            </Header>
        )}
    />
);

export default MainHeader;
