import { ReactElement } from "react"

const FormattedOutput = (props) => {
    let outputList = new Array<ReactElement>()

    if (props.disabled) return <div></div>

    props.diff.forEach((element, index) => {
        // now format the strings all into divs, and mark the removals and additions

        if (element.added) {
            // just pass the string
            if (props.onClick != null)
                outputList.push(
                    <span
                        style={{ whiteSpace: "pre-wrap" }}
                        className="change"
                        onClick={() => props.onClick(index)}
                    >
                        {element.value}
                    </span>)
            else
                outputList.push(<span style={{ whiteSpace: "pre-wrap" }} className="change">{element.value}</span>)
        } else if (element.removed) {
            // do not pass removals
        } else {
            outputList.push(<span style={{ whiteSpace: "pre-wrap" }}>{element.value}</span>)
        }

    })

    return <div>{outputList}</div>
}

export default FormattedOutput