import React, { useState, useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../../supabaseClient'
import { Button } from '@carbon/react'

const LoginPage = () => {

    const [session, setSession] = useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    if (!session) {
        return <div>
            <Auth
                supabaseClient={supabase}
                appearance={{
                    theme: ThemeSupa,
                    variables: {
                      default: {
                        colors: {
                          brand: "#0f62fe",
                          brandAccent: "#0b4ecb",
                        },
                      },
                    },
                  }}
                providers={['discord', 'google', 'github']}
            />
        </div>
    }
    return <div>
        <p>Logged in as {session.user.email}!</p>
        <Button onClick={() => supabase.auth.signOut()}>Sign out</Button>
    </div>
}

export default LoginPage;