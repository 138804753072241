import React from 'react';
import { Content, Theme } from '@carbon/react';
import MainHeader from './components/MainHeader';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import CorrectingPage from './content/CorrectingPage';
import LoginPage from './content/LoginPage';
import EnglishPage from './content/EnglishPage';

function App() {
    return (
        <>
            <Theme theme="g100">
                <MainHeader />
            </Theme>
            <Content>
                <Routes>
                    <Route exact path="/" element={<CorrectingPage />}/>
                    <Route path="english" element={<EnglishPage />}/>
                    <Route path="login" element={<LoginPage />}/>
                </Routes>
            </Content>
        </>
    );
}

export default App;
