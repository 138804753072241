import React, { useState, useEffect } from 'react'
import { supabase } from '../../supabaseClient'
import { TextArea, Button, InlineLoading } from '@carbon/react'
import { WarningAlt } from '@carbon/icons-react'
import FormattedOutput from '../../components/FormattedOutput'
import { Link } from 'react-router-dom'

const Diff = require('diff')

const CorrectingPage = () => {
    const [inputText, setInputText] = useState('') // is the current user input
    const [requestText, setRequestText] = useState('') // is the actual text that was last sent to the edge function
    const [response, setResponse] = useState({ value: '', loading: false }) // is the response from the edge function

    const [ready, setReady] = useState(false)

    const [session, setSession] = useState(null)

    const [diff, setDiff] = useState([])

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        let diff = Diff.diffWords(requestText.trim(), response.value.trim())
        setDiff(diff)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response.value])

    const invokeFunction = async () => {
        setRequestText(inputText)
        setResponse({ value: '', loading: true })
        const { data, error } = await supabase.functions.invoke("verbesserer", {
            body: {
                text: inputText,
            },
        })
        if (error) alert(error)
        setResponse({ value: data.result.choices[0].text, loading: false })
    }

    function handleInputChange(e) {
        setInputText(e.target.value)

        if (e.target.value.length >= 10) setReady(true)
        else setReady(false)
    }

    function clearTextFields() {
        setInputText('')
        setRequestText('')
        setResponse({ value: '', loading: false })
    }

    const CreateAccountHint = (props) => {
        if (props.signedIn) {
            return <></>
        }
        return <div className="create-account-hint">
            <p>Um mehr als 100 Zeichen zu verbessern, musst du dich zuerst <Link to="/login">anmelden</Link> oder <Link to="/login">registrieren</Link>.</p>
        </div>
    }

    const handleClick = (index: number) => {
        const contextSize = 20 // number of surrounding elements to include
        const startIndex = Math.max(0, index - contextSize)
        const endIndex = Math.min(diff.length - 1, index + contextSize)
        const context = diff.slice(startIndex, endIndex + 1)
        
        // open the inspektor toggletip using the context
        console.log(context)
    }

    return <div>
        <h1>Verbesserer</h1>
        <h2>Verbessert Rechtschreib-, Grammatik-, Fall- und Beistrichfehler.</h2>
        <br />

        <WarningAlt />
        <p className="warning">Dieses Tool ist noch in Entwicklung, und hat derzeit Probleme, falls der Satzbau der Eingabe falsch ist. Außerdem kann es zu Fehlern bei zu kurzen Sätzen kommen oder wenn ganz am Anfang der Eingabe zu viele Fehler pro Wort sind.</p>
        <br />

        <CreateAccountHint signedIn={session} />

        <TextArea labelText="Texteingabe" placeholder="Schraib einenn Schmarrrn zusammen..." enableCounter maxCount={session ? 2048 : 100} onChange={handleInputChange} value={inputText} />
        <Button onClick={invokeFunction} disabled={!ready || response.loading} >Absenden</Button>
        <Button onClick={clearTextFields} kind="secondary" >Zurücksetzen</Button>
        <InlineLoading status={response.loading ? 'active' : 'inactive'} />

        <div className="output">
            <FormattedOutput disabled={response.loading || !response.value} diff={diff} onClick={handleClick} />
        </div>
    </div>
}

export default CorrectingPage